import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Modal, Form } from "react-bootstrap";
import firebase from "firebase";
import Select from "react-select";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import Upload from "../Images/upload.png";
import { baseUrl } from "../../services/config";
import DeleteModal from './DeleteModal'
import makeAnimated from 'react-select/animated';
import { Link } from 'react-router-dom'
import { notificationUrl } from "../../firebase/config";
import { removeDuplicates } from "../pages/utilts";
const useStyles = makeStyles({
  root: {
    width: 280,
    margin: 10,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function GroupCard({ item, loadGroups, userList }) {





  useEffect(() => {
    if (item.users.length > 0) {
      item.users.forEach((data) => {
        data["color"] = getColor();
      });
    }
  }, []);

  const classes = useStyles();



  // report states
  const [reporttitle, setReporttitle] = useState("");
  const [reportdescription, setreportdescription] = useState("");
  const [reporturl, seturl] = useState("");

  //end

  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [addLoading, setaddLoading] = useState(false);

  const [groupName, setgroupName] = useState(item.name);
  const [groupMember, setgroupMember] = useState([]);
  // const [groupsUsers,setgroupsUsers]=useState({})

  const [editModal, seteditModal] = useState(false);
  const [deleteModel, setdeleteModel] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState(
    []
  );
  const [show, setShow] = useState(false);

  const authtoken = localStorage.getItem("userToken");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imagePrivew, setImagePreview] = useState(item?.image?.original_url);
  const [uploadImage, setUploadImage] = useState("");
  const [title, settitle] = useState("");
  const [image, setimage] = useState("");
  const [show2, setShow2] = useState(false);
  const [description, setdescription] = useState("");
  const handleClose2 = () => setShow2(false);
  const [selectedUser, setselectedUser] = useState({});
  // const [groupName, setgroupName] = useState('')
  const handleShow2 = (item) => {
    console.log(item.device_ids, "user===>");
    setselectedUser(item);
    setShow2(true);
    //  if (item.device_ids) {
    //    toast.error('User unable to receive notification')
    //  } else {

    //  }
  };
  //  const userArray = Object.values(item.groupsUsers);
  //  setUsers(userArray);
  console.log(item, "this is item");

  // console.log(groupsUsers.name);
  console.log(groupName);
  console.log(groupMember);
  const sendNotifications = async () => {
    item.users && item.users.map(async (subItem, index) => {
      const pushkey = firebase
        .database()
        .ref("notifications")
        .push().key;
      firebase
        .database()
        .ref("notifications")
        .child(subItem.id)
        .child(pushkey)
        .set({
          description: description,
          title: title,
          image: subItem?.image?.original_url || "",
          notificationId: pushkey,
          userName: subItem.name,
          userId: subItem.id,
          timeStamp: Date.now(),
          

        })
        .then(() => {
          setShow2(false);
          if (Object.values(groupMember).length == index + 1) {
            toast.success("Notifications send successfully");
          }
        });

        removeDuplicates(subItem.device_ids).map(async (ids) => {
        console.log(ids, "ids")
        const notification = {
          message: description,
          title: title,
          // sound: "default", // notification sound
        };
        console.log(notification, "data")
        await fetch(`${notificationUrl}`, {
          headers: {
            "Content-Type": "application/json",
            // Authorization:
            //   "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4",
          },
          method: "POST",
          body: JSON.stringify({
            ...notification,
            deviceToken: ids,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response of notifications===>", data);
            setShow2(false);
          })
          .catch((error) => {
            console.log("notification error====>", error);
          });
      });
    });
  };

  const getTime = (timestamp) => {
    const now = new Date();
    const elapsedMilliseconds = now.getTime() - timestamp;

    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedDays = Math.floor(elapsedHours / 24);

    let message = "";

    if (elapsedDays > 0) {
      message = elapsedDays + "d" + " " + "ago";
    } else if (elapsedHours > 0) {
      message = elapsedHours + "h" + " " + "ago";
    } else if (elapsedMinutes > 0) {
      message = elapsedMinutes + "m" + " " + "ago";
    } else {
      message = "Today";
    }

    return message;
  };

  const getColor = () => {
    let color = "";

    color = Math.floor(Math.random() * 16777215).toString(16);
    return color;
  };

  const handleAdd = () => {
    if (groupName == "") {
      toast.error("Group name is required");
      return;
    }
    if (selectedUsers.length > 0) {
      assignUsers()
    }
    setUploadLoading(true)
    const fdata = new FormData();
    fdata.append(`name`, groupName);
    if (uploadImage) {
      fdata.append(`image`, uploadImage);
    }
    fetch(`${baseUrl}/admin/groups/${selectedId}?_method=PUT`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setUploadLoading(false)
          loadGroups()
          toast.success(`Group Update Successfully`);
          seteditModal(false);
        } else {
          toast.error(data.metadata.message);
        }
      })
      .catch((err) => {
        toast.error(`Something went wronge`);
        setUploadLoading(false)
        seteditModal(true)
      });
  };

  const assignUsers = () => {
    const fdata = new FormData();
    for (let i = 0; i < selectedUsers.length; i++) {
      fdata.append(`user_ids[${i}]`, selectedUsers[i].value);
    }

    fetch(`${baseUrl}/admin/groups/${selectedId}/assign-users`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
      })
      .catch((err) => {
      });
  }

  const viewGroup = () => {
    history.push(`/chats/${item.id}`);
  };

  console.log(userList);
  const animatedComponents = makeAnimated();
  const getAssignedUsers = (id) => {
    fetch(`${baseUrl}/admin/groups/${id}/assigned-users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        let temparr = [];
        if (data.payload.length > 0) {
          data.payload.forEach((user) => {
            temparr.push({
              label: user.name,
              value: user.id,
            });
          });
        }
        setSelectedUsers(temparr);
        seteditModal(true)
      })
      .catch((err) => console.log(err));
  }


  const handleClose = () => setShow(false);



  const handleadd = () => {
    if (reporttitle == "" || reportdescription == "") {
      toast.error("Please Enter All Fields");
    } else {

      item.users && item.users.map((user,i) => {

        const newObject = {
          user_id: user.id,
          title: reporttitle,
          description: reportdescription,
          url: reporturl,
        };

        fetch(`${baseUrl}/admin/user-report`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authtoken}`,
            // 'X-Requested-With': 'XMLHttpRequest'
          },
          body: JSON.stringify(newObject),
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            if (data.metadata.success) {
              
              setaddLoading(false);
              notification(user);
              if (i + 1 == item.users.length) {
                toast.success('Report send successfully')
                handleClose();
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });


      })


    }
  };


  const notification = async (userData) => {


    //////['token1','token2']

    console.log("user id ====>", userData.device_ids)

    removeDuplicates(userData.device_ids).map(async (item, index) => {
      const notification = {
        message: reportdescription,
        title: reporttitle,
        // sound: 'default' // notification sound 
      };


      await fetch(notificationUrl, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4"
        },
        method: "POST",
        body: JSON.stringify({
          ...notification,
          deviceToken: item,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response of notifications===>", data);
        })
        .catch((error) => {
          console.log("notification error====>", error);
        });

    });

  };



  return (
    <div className="col-md-4">
      <Card className={classes.root}>
        <CardContent>
          <div className="groupImageContainer">
            {item.image ? <img src={item?.image?.original_url} alt="groupimage" /> : ""}
          </div>
          <Typography
            style={{ paddingTop: "5px" }}
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {getTime(item.timestamp)}
          </Typography>
          <Typography variant="h5" component="h2">
            {item.name}
          </Typography>
          {/* <Typography className={classes.pos} color="textSecondary">
                    adjective
                </Typography> */}
          <AvatarGroup style={{ height: "25px" }} className="ml-3 mt-3" max={4}>
            {item.users?.map((avt) => (
              <>
                <Avatar
                  style={{ backgroundColor: `#${avt.color}` }}
                  alt={avt.name.toUpperCase()}
                  src={avt.image != null ? avt?.image?.original_url : "#"}
                />
              </>
            ))}
          </AvatarGroup>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Link to={{ pathname: `/chats/${item.id}`, state: { item } }}>
            <Button className="btn btn-primary" size="small">
              View
            </Button>
          </Link>
          <Button
            onClick={() => {
              setgroupName(item.name)
              setImagePreview(item.image.original_url)
              getAssignedUsers(item.id)
              setselectedId(item.id)
            }}
            className="btn btn-dark"
            size="small"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              setselectedId(item.id)
              setdeleteModel(true)
            }}
            className="btn btn-danger"
            size="small"
          >
            Delete
          </Button>
        </CardActions>
        <div
          className="mb-5"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <Button
            onClick={() => handleShow2(item)}
            className="btn btn-success px-5"
            size="small"
            style={{ color: "white" }}
          >
            Send Notification
          </Button>
          <Button
            onClick={() => setShow(item)}
            className="btn btn-primary px-2 ml-2"
            size="small"
            style={{ color: "white" }}
          >
            Send Report
          </Button>
        </div>

        <DeleteModal
          HideModal={() => setdeleteModel(false)}
          method="DELETE"
          reload={loadGroups}
          visible={deleteModel}
          url={`${baseUrl}/admin/groups/${selectedId}`}
        />
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show2}
          onHide={handleClose2}
        >
          <Modal.Header>
            <Modal.Title>Send Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control
              required
              onChange={(e) => {
                settitle(e.target.value);
              }}
              type="text"
              placeholder="Title"
            />
            <Form.Group
              className="mt-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Control
                placeholder="Descriptions"
                onChange={(e) => {
                  setdescription(e.target.value);
                }}
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              className="btn btn-danger mx-2"
              variant="danger"
              onClick={handleClose2}
            >
              Close
            </Button>
            <Button
              className="btn btn-primary"
              variant="primary"
              onClick={() => sendNotifications()}
            >
              Send
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={editModal}>
          <Modal.Header>
            <Modal.Title>Edit Group</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="col-md-12">
              <label>Group Name</label>
              <input
                value={groupName}
                onChange={(e) => setgroupName(e.target.value)}
                placeholder="Group Name"
                className="form-control"
              />
            </div>
            <div className="col-md-12 mt-2">
              <label>Group Members</label>

              <Select
                defaultValue={selectedUsers}
                isMulti
                name="colors"
                onChange={(e) => setSelectedUsers(e)}
                options={userList}
                className="basic-multi-select"
                classNamePrefix="select"
              />

            </div>
            <div className="form-group row mt-4">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="avatar-upload-input text-center">
                  <img src={Upload} alt="img" />
                  <h2>Upload Image</h2>
                  <div className="avatar-edit-input">
                    <input
                      className="form-control"
                      name="file"
                      id="imagesupload"
                      type={"file"}
                      onChange={(e) => {
                        setUploadImage(e.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
              {uploadImage ? (
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    marginLeft: "12px",
                    marginTop: "3px",
                  }}
                  src={URL.createObjectURL(uploadImage)}
                />
              ) : imagePrivew ? (
                <img
                  style={{
                    height: "100px",
                    width: "100px",
                    marginLeft: "12px",
                    marginTop: "3px",
                  }}
                  src={imagePrivew}
                />
              ) : (
                ""
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              onClick={() => {
                setSelectedUsers([])
                setUploadImage("");
                seteditModal(false);
              }}
              className="btn btn-danger"
            >
              Close
            </button>
            <button
              disabled={uploadLoading}
              onClick={handleAdd}
              className="btn btn-primary"
            >
              Update
            </button>
          </Modal.Footer>
        </Modal>
      </Card>


      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title>Send Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30vw",
                position: "absolute",
                top: "10px",
                bottom: "15px",
              }}
            >
              <span className="spinner-border text-success text-center"></span>
            </div>
          ) : (
            <>
              <Form.Control
                required
                type="text"
                placeholder="Title"
                onChange={(e) => {
                  setReporttitle(e.target.value);
                }}
              />

              <Form.Control
                onChange={(e) => {
                  setreportdescription(e.target.value);
                }}
                className="mt-2"
                type="text"
                placeholder="Description"
              />

              <Form.Control
                onChange={(e) => {
                  seturl(e.target.value);
                }}
                className="mt-2"
                type="text"
                placeholder="URL"
              />
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleadd}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
